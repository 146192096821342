import React from 'react'

import Fade from 'react-reveal/Fade'
const Value = ({icon, title, text }) => (
    <Fade right cascade>
        <article className="sm:w-3/12 w-full mb-10 sm:mb-0">
            <img className="sm:w-auto w-16" src={icon} alt={title} />
            <h4 className="text-torq-200 font-display uppercase tracking-widest">{title}</h4>
            <p>{text}</p>
        </article>
    </Fade>
)

export default Value
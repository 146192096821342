import React from 'react'
import Ideathon from "../../images/logo/ideathon-hub.png"
import Lsetf from "../../images/logo/lsetf.png"
import Bethseda from "../../images/logo/bethseda.png"

import Fade from 'react-reveal/Fade'

export const Community = () => (
    <section className="bg-yellow sm:py-28 py-16">
            <Fade bottom cascade>
                <div className="max-w-screen-md sm:text-center mx-auto px-12">
                        <h2>Community helping communities</h2>
                        <p>Beyond our paid membership system, we also support social and communal development through the TGIC Social Responsibility Fund which we have deployed to support a number of initiatives. These include our support of Ideation Hub Africa’s work towards sustainable social change; a recent partnership with LSETF to raise funds for a business to recover from the recent looting in Lagos state; and taking it upon ourselves to sponsor 10 children to school every year through the Bethesda Child Support Agency (a part of Freedom Foundation).</p>
                        <div className="text-center ">
                            <img src={Ideathon} alt="Ideathon Hub" className="inline-block sm:mr-4 sm:w-auto w-4/12" />
                            <img src={Lsetf} alt="LSETF" className="inline-block sm:mr-4 sm:w-auto w-4/12" />
                            <img src={Bethseda} alt="Bethseda Child Support Agency" className="inline-block sm:w-auto w-4/12" />
                    </div>
            </div>
        </Fade>
    </section>
)


import React from 'react'
import Fade  from 'react-reveal/Fade'


export const PageHeader = ({heading, paragraph, color="blue"}) => (
    
        <header className={`flex sm:h-96 sm:min-h-70 min-h-50 sm:py-24 py-6 items-center justify-center bg-`+color}>
            <Fade bottom cascade>
            <div className="max-w-screen-lg  px-12 sm:text-center text-left">
                <h1 className="text-torq-200">{heading}</h1>
                <p className="sm:w-9/12 w-full mx-auto">{paragraph}</p>
            </div>
            </Fade>
        </header>

)
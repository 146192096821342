import React from 'react'

import Layout from "../components/layout"
import {CoreValues, MissionStatement, Community, Board} from "../components/mission"
import {PageHeader, Subfooter} from "../components/utils"


import SEO from "../components/seo"

const Mission = () => {
    return(
        <Layout color="yellow-100">
            <SEO title="Mission" />
            <PageHeader color="yellow-100" heading={<>We help people know enough about money to fund the life of their dreams.</>}
            paragraph={<>The Green Investment Club (TGIC) is a community-based investment club focused on helping young people live the lives they want by providing access to financial education, insights and investment opportunities towards achieving their financial goals.
                <br/><br/>
                We hold 3 values dear;</>} />
            <CoreValues />
            <MissionStatement />
            <Community />
            <Board />
            <Subfooter text={<>Ready to take that one big <br className="sm:block hidden"/>step further?</>} />
        </Layout>
        
    )
}

export default Mission
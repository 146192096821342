import React from 'react'
import Fade from 'react-reveal/Fade'

import Img from 'gatsby-image'

const Member = ({image, name, bio, date}) => {
    return (
        <Fade right cascade>

        <article className="sm:w-6/12 w-full sm:odd:pr-16 sm:even:pl-16 flex flex-wrap sm:mb-20 mb-16">
            <header className="sm:w-4/12 w-7/12">
                <Img fluid={image} alt={name} className="w-10/12 sm:-mt-6" />
            
            </header>
            <content className="sm:w-8/12 w-full sm:mt-0 mt-8">
                <h3 className="font-display">{name}</h3>
                <h4 className="font-display font-normal -mt-3 text-torq-200">Member since {date}</h4>
                <p>{bio}</p>
            </content>
        </article>
        </Fade>
    )
}

export default Member
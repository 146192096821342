import React from "react"
import ListIcon from "../../images/icons/mark.svg"

export const ListItem = ({ text, icon = true }) => {
  return (
    <div className=" w-full flex space-x-2 mb-4 ">
      <div className="md:w-6 w-4 pt-2 ">
        {icon ? (
          <img src={ListIcon} alt="List icon" className="md:w-6 w-4 mb-0" />
        ) : null}
      </div>
      <div className="w-11/12">{text}</div>
    </div>
  )
}

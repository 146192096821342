import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Member from "./member"

export const Board = () => {

    const boardMembers = useStaticQuery(graphql`
    query  {
        allContentfulBoardMembers(sort: {fields: createdAt, order: DESC}) {
          edges {
            node {
              image {
                fluid(maxWidth: 500){
                  ...GatsbyContentfulFluid
               } 
              }
              date
              bio {
                bio
              }
              name
            }
          }
        }
      }
    `)

    const allBoardMember = boardMembers.allContentfulBoardMembers.edges.map((member ,i) => <Member key={i} name={member.node.name} 
    bio={member.node.bio.bio} image={member.node.image.fluid} date={member.node.date} />)

    return(
        <article className="sm:py-28 py-16 px-12">
            <header className="max-w-screen-md mx-auto sm:text-center">
                <h2>Members of our TGIC advisory board  </h2>
                <p className="sm:w-10/12 w-full mx-auto">We have recently selected some of our full-time members to make up our board of advisors and contribute to the leadership of the organization as we fulfil our mission to deliver great value for the community.</p>
            </header>

            <content className="max-w-screen-xl mx-auto flex sm:pt-32 pt-16  justify-between flex-wrap overflow-hidden">
                {allBoardMember}
            </content>
        </article>
    )
}
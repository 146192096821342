import React from 'react'
import {Button} from "./"
import {Link} from 'gatsby'

import Fade from 'react-reveal/Fade'

export const Subfooter = ({text}) => (
    <Fade big>
        <section className="bg-torq-300 h-72 flex text-white text-center justify-center items-center px-12">
            <div className="max-w-screen-sm">
                <h2>{text}</h2>
                <Link to="/join-us#packages"><Button text="Become a Member" color="torq-200" textColor="white" /></Link>
            </div>
        </section>
    </Fade>
)
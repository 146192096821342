import React from 'react'
import Value from './value'

import Empathy from "../../../images/icons/empathy.svg"
import Integrity from "../../../images/icons/integrity.svg"
import Innovation from "../../../images/icons/innovation.svg"


export const CoreValues = () => {
    return(
        
            <div className="max-w-screen-xl flex  flex-wrap mx-auto justify-around sm:py-32 py-16 px-12 overflow-hidden">
                <Value icon={Empathy} title="Empathy" text="We understand that we are all building up from different starting points and hence we embrace peculiarities on size of income, individual background and more." />
                <Value icon={Integrity} title="Integrity" text="Due diligence is our barest minimum, and we do not shy away from telling the truth, every step of the way." />
                <Value icon={Innovation} title="Innovation" text="We are constantly exploring new ways, approaches and mediums to create more value for our members." />
            </div>
       
    )
}
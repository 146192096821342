import React from 'react'
import Tomie from "../../images/tomie-balogun.webp"

import Fade from 'react-reveal/Fade'

export const MissionStatement = () => {
    return(
        <>
            <div className="bg-blue h-80">
            </div>
            <Fade  cascade>

            <section className="max-w-screen-xl mx-auto sm:-mt-60 -mt-72 sm:pb-32 pb-16 px-12 flex flex-wrap">
                <div className="flex w-full justify-end mb-6">
                    <h2 className="sm:w-8/12 w-full">It all began with a simple mission</h2>
                </div>
                <div className="flex flex-wrap">
                    <div className="sm:w-3/12 sm:text-center w-8/12">
                        <img src={Tomie} className="w-10/12 sm:-mt-12 sm:mx-auto " alt="Tomie Balogun" />
                    </div>
                    <div className="w-1/12"></div>
                    <div className="sm:w-7/12 w-full mt-4 sm:mt-0">
                        <p>We have all either heard or had our own share of sad investment experiences, from the profits that never returned to opportunities we heard about only after it sold out. Most times, you don't even know where to begin from. With careers (outside finance) to prioritize, it is often hard for most people to do the due diligence that investment requires. </p><p>That financial education and diligence is exactly what Tomie Balogun took upon herself. In 2013, she started an investment club with four other MBA classmates at the time. They would pool individual funds together to get access to bigger investment opportunities; share the rewards and risks; and hold each accountable to their financial goals.</p>
                        <p className="sm:text-2xl text-lg">3 years on, The Green Investment Club boasts of over 1000 members, making it the largest in Nigeria and with diaspora membership from 20 other countries, as well as investments worth over $9 million, and a mission that continues to yield results, with more people getting closer to financial freedom.</p>
                    </div>
                    <div className="w-1/12"></div>
                </div>
            </section>
            </Fade>
        </>
        
    )
}

